import { template as template_5af6179aedfc49bab7554c8ec95d8710 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const LikesCell = template_5af6179aedfc49bab7554c8ec95d8710(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="likes"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="likes"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default LikesCell;
