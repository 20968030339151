import { template as template_b7f5c152c0ef429d840f4f38dd8a9124 } from "@ember/template-compiler";
const FKControlMenuContainer = template_b7f5c152c0ef429d840f4f38dd8a9124(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
