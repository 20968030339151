import { template as template_66e8cd0c38f74a8bad2e404e12cbd29f } from "@ember/template-compiler";
const FKLabel = template_66e8cd0c38f74a8bad2e404e12cbd29f(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
