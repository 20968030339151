import { template as template_f21387e699fd427a9883e6526b154261 } from "@ember/template-compiler";
const SidebarSectionMessage = template_f21387e699fd427a9883e6526b154261(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
